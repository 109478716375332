import '@fontsource/inter';
import {ChakraProvider} from '@chakra-ui/react';
import {customTheme} from 'theme';
import Routers from 'views/Routers';
import {AppProvider} from './AppContext';
import {WagmiConfig} from 'wagmi';
import {client} from './chains/wagmi';

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <WagmiConfig client={client}>
        <AppProvider>
          <Routers />
        </AppProvider>
      </WagmiConfig>
    </ChakraProvider>
  );
}

export default App;
