import {createContext, ReactNode, useMemo, useState} from 'react';
import {makeClient} from './client/configure';
import {Provider} from 'urql';
import {ConnectKitProvider} from 'connectkit';
import {useColorMode} from '@chakra-ui/react';

interface AppContextType {
  currency: string;
  chainId: number;
  setCurrency: (currency: string) => void;
  setChainId: (chainId: number) => void;
}

type AppProviderProps = {
  children: ReactNode;
};
export const AppContext = createContext<AppContextType>({
  currency: 'usd',
  setCurrency(currency) {},
  chainId: 1,
  setChainId(chainId) {},
});

export const AppProvider = ({children}: AppProviderProps) => {
  const {colorMode} = useColorMode();
  const [currency, setCurrency] = useState('usd');
  const [chainId, setChainId] = useState(1);
  const client = useMemo(() => {
    return makeClient(process.env.REACT_APP_GRAPHQL_ENDPOINT || '');
  }, []);
  return (
    <ConnectKitProvider theme="auto" mode={colorMode}>
      <AppContext.Provider
        value={{
          currency,
          setCurrency,
          chainId,
          setChainId,
        }}
      >
        <Provider value={client}>{children}</Provider>
      </AppContext.Provider>
    </ConnectKitProvider>
  );
};
