const BASE_RPC = 'https://rpc.ankr.com';
const API_KEY = process.env.REACT_APP_ANKR_API_KEY;

const rpcPaths: Record<number, string> = {
  1: 'eth',
  42161: 'arbitrum',
  43114: 'avalanche',
  56: 'bsc',
  97: 'bsc_testnet_chapel',
  1284: 'moonbeam',
  10: 'optimism',
  69: 'optimism_testnet',
  137: 'polygon',
  80001: 'polygon_mumbai',
};

export const getRpcUrl = (chainId: number) => {
  if (rpcPaths.hasOwnProperty(chainId)) {
    const endpoint = `${BASE_RPC}/${rpcPaths[chainId]}`;
    return API_KEY ? `${endpoint}/${API_KEY}` : endpoint;
  }
  return null;
};
