import {
  createClient,
  dedupExchange,
  fetchExchange,
  Exchange,
  subscriptionExchange,
} from 'urql';
import {retryExchange} from '@urql/exchange-retry';
import {refocusExchange} from '@urql/exchange-refocus';
import {cacheExchange} from '@urql/exchange-graphcache';
import {createClient as createWSClient} from 'graphql-ws';
import {requestPolicyExchange} from '@urql/exchange-request-policy';
import schema from './schema.json';

export const makeClient = (url: string) => {
  const wsClient = createWSClient({
    url: url.replace('https', 'wss').replace('http', 'ws'),
    lazy: true,
  });

  const exchanges: Exchange[] = [
    dedupExchange,
    requestPolicyExchange({}),
    refocusExchange(),
    cacheExchange({
      // @ts-ignore
      schema,
      keys: {
        OutputSupportedTokens: data => null,
      },
      updates: {},
    }),
    retryExchange({
      initialDelayMs: 1000,
      maxDelayMs: 15000,
      randomDelay: true,
      maxNumberAttempts: 2,
      retryIf: error => {
        return !!(error.graphQLErrors.length > 0 || error.networkError);
      },
    }),
    fetchExchange,
    subscriptionExchange({
      forwardSubscription(operation) {
        return {
          subscribe: sink => {
            const dispose = wsClient.subscribe(operation, sink);
            return {
              unsubscribe: dispose,
            };
          },
        };
      },
    }),
  ];

  return createClient({
    url,
    suspense: false,
    exchanges,
  });
};
